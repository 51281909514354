<template>
    <!-- Sidebar Right -->
    <aside id="app-aside" class="aside" :class="{'aside-notactive' : !asideActive,'aside-active' : asideActive}">
    <!--<div class="list-group list-group-flush inline-block">
        <a href="#" class="list-group-item list-group-item-action d-flex flex-row"><i class="fa fa-bars fa-lg mr-3"></i>Teste</a>
        <a href="#" class="list-group-item list-group-item-action d-flex flex-row"><i class="fa fa-bars fa-lg mr-3"></i>Shortcuts</a>
        <a href="#" class="list-group-item list-group-item-action d-flex flex-row"><i class="fa fa-bars fa-lg mr-3"></i>Overview</a>
        <a href="#" class="list-group-item list-group-item-action d-flex flex-row"><i class="fa fa-bars fa-lg mr-3"></i>Events</a>
        <a href="#" class="list-group-item list-group-item-action d-flex flex-row"><i class="fa fa-bars fa-lg mr-3"></i>Profile</a>
        <a href="#" class="list-group-item list-group-item-action d-flex flex-row"><i class="fa fa-bars fa-lg mr-3"></i>Status</a>
    </div>-->
    </aside>  
</template>

<script>
export default {
    name: 'LayoutDefaultASide',
    props: ['asideActive']
}
</script>

<style>

</style>