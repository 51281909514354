<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'app',
  methods: {
    onLoad (){
      const path = window.location.pathname;
      //console.log(path);
      if (path === '/portal' && !this.infoLogado.usu_codigo){
        this.$router.push({path: '/portal/logar'})  
      } else
      if (!this.infoLogado.usu_codigo){    
        this.$router.push({path: '/logar'})  
      }
    }
  },
  mounted () {
    this.onLoad();    
  },
  updated () {

  },
  created () {

  },
  computed: {
    ...mapState('Login',['infoLogado','layout'])
  },
  watch: {
    infoLogado () {
      this.onLoad();
    }
  }
}
</script>

<style lang="scss">
  @import './assets/scss/style';
</style>
