import * as MutationTypes from './MutationTypes';
import axios from "@/http";

const state = {
    mensagens: [],
    alertas: [],
    atividades: [],
    notificacoes: []
};

const mutations = {
    [MutationTypes.APP_MENSAGENS](state, {mensagens}) {
        state.mensagens = mensagens
    },
    [MutationTypes.APP_ALERTAS](state, {alertas}) {
        state.alertas = alertas;
    },
    [MutationTypes.APP_ATIVIDADES](state, {atividades}) {
        state.atividades = atividades
    },
    [MutationTypes.APP_ATU_ALERTAS](state, data) {
        state.alertas.push({...data});
    },
};

const actions = {
    async [MutationTypes.APP_ATUALIZA]({commit}) {
        
        await axios.get(`/usuario/sidebar`)
        .then(response => {
            commit(MutationTypes.APP_MENSAGENS, response.data);
            commit(MutationTypes.APP_ATIVIDADES, response.data);
            commit(MutationTypes.APP_ALERTAS, response.data);
        })
        .catch(response => {
            console.log(res.message)
        })
    },
    async [MutationTypes.APP_ATU_ALERTAS]({commit},data) {        
        commit(MutationTypes.APP_ATU_ALERTAS, data);
    }
};

const getters = {
    mensagens: state => state.mensagens,
    alertas: state => state.alertas,
    atividades: state => state.atividades,
    notificacoes: state => state.notificacoes,
};

export default{
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
