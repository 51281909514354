<template>
<transition :name="transition" @after-enter="$emit('after-enter')" @after-leave="$emit('after-leave')">
  <div class="modalsync" :id="id" tabindex="-1" role="dialog">
    <div class="modalsync-dialog">
      <div class="modalsync-content">
        <!-- header-->
        <div class="modalsync-header" v-if="$slots.header">
          <slot name="header"></slot>
        </div>
        <div class="modalsync-header" v-else-if="header">
          <button class="close" type="button" @click="closeModal">×</button>
          <h4 class="modalsync-title" v-if="header" v-html="header"></h4>
        </div>
        <!-- body-->
        <slot v-if="$slots.body" name="body"></slot>
        <div class="modalsync-body" v-else>
          <slot></slot>
        </div>
        <!-- footer-->
        <div class="modalsync-footer" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
        <div class="modalsync-footer" v-else-if="footer">
          <div class="btn btn-default" @click="closeModal">{{ cancelText || '取消' }}</div>
          <div class="btn btn-primary" :disabled="disabled" @click="confirmModal">{{ confirmText || '确定' }}</div>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>
<script>
export default {
  props: {
    id: [Number, String],
    header: String,
    footer: Boolean,
    disabled: Boolean,
    confirmText: String,
    cancelText: String,
    transition: {
      type: String,
      default: 'fade',
    },
  },
  methods: {
    closeModal() {
      this.$modal.close()
    },
    confirmModal() {
      if (this.disabled) return
      this.$emit('confirm')
    },
  },
}
</script>

<style scoped>

.modalsync {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modalsync-open .modalsync {
  overflow-x: hidden;
  overflow-y: auto;
}
.modalsync-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modalsync-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  background-clip: padding-box;
  outline: 0;
}
.modalsync-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modalsync-header:before,
.modalsync-header:after {
  content: " ";
  display: table;
}
.modalsync-header:after {
  clear: both;
}
.modalsync-header .close {
  margin-top: -2px;
}
.modalsync-title {
  margin: 0;
  line-height: 1.428571428571429;
}
.modalsync-body {
  position: relative;
  padding: 15px;
}
.modalsync-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modalsync-footer:before,
.modalsync-footer:after {
  content: " ";
  display: table;
}
.modalsync-footer:after {
  clear: both;
}
.modalsync-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modalsync-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modalsync-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modalsync-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modalsync-dialog {
    width: 800px;
    margin: 30px auto;
  }
  .modalsync-content {
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  }
}
.modalsync-sm {
  width: 300px;
}
@media (min-width: 992px) {
  .modalsync-lg {
    width: 900px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}


</style>

