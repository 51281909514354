import { render, staticRenderFns } from "./MctLoading.vue?vue&type=template&id=5aa72211&scoped=true"
import script from "./MctLoading.vue?vue&type=script&lang=js"
export * from "./MctLoading.vue?vue&type=script&lang=js"
import style0 from "./MctLoading.vue?vue&type=style&index=0&id=5aa72211&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa72211",
  null
  
)

export default component.exports