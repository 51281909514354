function load(component) {
    return () => import(`@/components/comum/${component}`)
}

function loadC(component) {
    return () => import(`@/components/${component}`)
}

const MctLogarPortal = () => import('@/components/seguranca/MctLogarPortal')

export default [

    { path: '/portal/logar', name: 'PortalLogar', component: MctLogarPortal},
    { path: '/portal/matriculas', name: 'PortalMatriculas', component: loadC('modulos/portal/Matriculas')},
    { path: '/portal/menu', name: 'PortalMenu', component: loadC('modulos/portal/MenuPortal')},

    { path: '/portal/notas/:entidade/:escola/:ano_letivo/:turma/:horario', name: 'PortalNotas', component: loadC('modulos/portal/NotasFaltas')},
    { path: '/portal/boletim/:entidade/:escola/:ano_letivo/:turma/:aluno', name: 'PortalBoletim', component: loadC('modulos/portal/Boletim')},
    { path: '/portal/horarios/:entidade/:escola/:ano_letivo/:turma/:horario', name: 'PortalHorarios', component: loadC('modulos/portal/Horarios')},
    { path: '/portal/calendario/:entidade/:escola/:ano_letivo/:turma', name: 'PortalCalendario', component: loadC('modulos/portal/Calendario')},
    { path: '/portal/conteudo/:entidade/:escola/:ano_letivo/:turma', name: 'PortalConteudo', component: loadC('modulos/portal/Conteudo')},
        
    { path: '/portal/dados', name: 'PortalDadosPessoais', component: loadC('modulos/portal/DadosPessoais')},

];
