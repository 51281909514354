function load(component) {
  return () => import(`@/components/comum/${component}`)
}

function loadC(component) {
  return () => import(`@/components/${component}`)
}

  export default [
    {
      path: '/modulos',
      name: 'Módulos',
      meta: {
        breadcrumb: [{
          text: 'Módulos'
        }]
      },
      component: loadC('seguranca/MctModulos')
    },
    {
      path: '/submodulos',
      name: 'Sub-Módulos',
      meta: {
        breadcrumb: [{
          text: 'Sub-Módulos'
        }]
      },
      component: loadC('seguranca/MctSubModulos')
    },  
    {
      path: '/alterarsenha',
      name: 'Alterar Senha',
      meta: {
        breadcrumb: [{
          text: 'Alterar Senha'
        }]
      },
      component: loadC('seguranca/MctAlterarSenha')
    },
    {
      path: '/privilegios',
      name: 'Privilégios',
      meta: {
        breadcrumb: [{
          text: 'Privilégios'
        }]
      },
      props: {source:"privilegios/novo",titulo:"Privilégios"},
      component: loadC('seguranca/MctPrivilegios')
    },
    {
      path: '/exercicios',
      name: 'Exrcícios',
      meta: {
        breadcrumb: [{
          text: 'Exrcícios'
        }]
      },
      component: loadC('seguranca/MctExercicios')
    },
    {
      path: '/upload',
      name: 'UpLoad',
      meta: {
        breadcrumb: [{
          text: 'UpLoad'
        }]
      },
      component: load('MctFileUpload')
    },
    { path:'/modulo', name: 'Módulo', redirect: '/modulo/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Módulos', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Módulos'} 
          ]},
          props: {source:"modulo/lista"}},
        { path: 'novo', name: 'Novo Módulo', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Módulos', to: '/modulo' }, 
            { text: 'Novo Módulo' }
          ]},      
          props: {source:"modulo/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Módulo', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Módulos', to: '/modulo' }, 
            { text: 'Editar Módulo' }
          ]},
          props: {source:"modulo/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Módulo', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Módulos', to: '/modulo' }, 
            { text: 'Deletar Módulo' }
          ]},      
          props: {source:"modulo/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Módulo', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Módulos', to: '/modulo' }, 
            { text: 'Visualizar Módulo' }
          ]},
          props: {source:"modulo/editar",modo:'visualizar'} },
      ]
    },
    { path:'/submodulo', name: 'Sub-Módulo', redirect: '/submodulo/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Sub-Módulos', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Sub-Módulos'} 
          ]},
          props: {source:"submodulo/lista"}},
        { path: 'novo', name: 'Novo Sub-Módulo', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Sub-Módulos', to: '/submodulo' }, 
            { text: 'Novo Sub-Módulo' }
          ]},      
          props: {source:"submodulo/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Sub-Módulo', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Sub-Módulos', to: '/submodulo' }, 
            { text: 'Editar Sub-Módulo' }
          ]},
          props: {source:"submodulo/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Sub-Módulo', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Sub-Módulos', to: '/submodulo' }, 
            { text: 'Deletar Sub-Módulo' }
          ]},      
          props: {source:"submodulo/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Sub-Módulo', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Sub-Módulos', to: '/submodulo' }, 
            { text: 'Visualizar Sub-Módulo' }
          ]},
          props: {source:"submodulo/editar",modo:'visualizar'} },
      ]
    },
    { path:'/perfil', name: 'Perfil', redirect: '/perfil/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Perfis', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Perfis'} 
          ]},
          props: {source:"perfil/lista"}},
        { path: 'novo', name: 'Novo Perfil', component: loadC('seguranca/MctPerfilCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Perfis', to: '/perfil' }, 
            { text: 'Novo Perfil' }
          ]},      
          props: {source:"perfil/novo",modo:'novo'} },
        { path: ':per_codigo/:ent_codigo/editar', name: 'Editar Perfil', component: loadC('seguranca/MctPerfilCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Perfis', to: '/perfil' }, 
            { text: 'Editar Perfil' }
          ]},
          props: {source:"perfil/editar",modo:'editar'} },
        { path: ':per_codigo/:ent_codigo/deletar', name: 'Deletar Perfil', component: loadC('seguranca/MctPerfilCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Perfis', to: '/perfil' }, 
            { text: 'Deletar Perfil' }
          ]},      
          props: {source:"perfil/deletar",modo:'deletar'} },
        { path: ':per_codigo/:ent_codigo/visualizar', name: 'Visualizar Perfil', component: loadC('seguranca/MctPerfilCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Perfis', to: '/perfil' }, 
            { text: 'Visualizar Perfil' }
          ]},
          props: {source:"perfil/editar",modo:'visualizar'} },
      ]
    },
    { path:'/usuarios', name: 'Usuário', redirect: '/usuarios/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Usuários', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Usuários'} 
          ]},
          props: {source:"usuarios/lista"}},
        { path: 'novo', name: 'Novo Usuário', component: loadC('seguranca/MctUsuarioCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Usuários', to: '/usuarios' }, 
            { text: 'Novo Usuário' }
          ]},      
          props: {source:"usuarios/novo",modo:'novo'} },
        { path: ':usu_codigo/:ent_codigo/editar', name: 'Editar Usuário', component: loadC('seguranca/MctUsuarioCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Usuários', to: '/usuarios' }, 
            { text: 'Editar Usuário' }
          ]},
          props: {source:"usuarios/editar",modo:'editar'} },
        { path: ':usu_codigo/:ent_codigo/deletar', name: 'Deletar Usuário', component: loadC('seguranca/MctUsuarioCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Usuários', to: '/usuarios' }, 
            { text: 'Deletar Usuário' }
          ]},      
          props: {source:"usuarios/deletar",modo:'deletar'} },
        { path: ':usu_codigo/:ent_codigo/visualizar', name: 'Visualizar Usuário', component: loadC('seguranca/MctUsuarioCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Usuários', to: '/usuarios' }, 
            { text: 'Visualizar Usuário' }
          ]},
          props: {source:"usuarios/editar",modo:'visualizar'} },
      ]
    },
    { path:'/menu', name: 'Menu', redirect: '/menu/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Menus', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Menus'} 
          ]},
          props: {source:"menu/lista"}},
        { path: 'novo', name: 'Novo Menu', component: loadC('seguranca/MctMenuCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Menus', to: '/menu' }, 
            { text: 'Novo Menu' }
          ]},      
          props: {source:"menu/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Menu', component: loadC('seguranca/MctMenuCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Menus', to: '/menu' }, 
            { text: 'Editar Menu' }
          ]},
          props: {source:"menu/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Menu', component: loadC('seguranca/MctMenuCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Menus', to: '/menu' }, 
            { text: 'Deletar Menu' }
          ]},      
          props: {source:"menu/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Menu', component: loadC('seguranca/MctMenuCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Menus', to: '/menu' }, 
            { text: 'Visualizar Menu' }
          ]},
          props: {source:"menu/editar",modo:'visualizar'} },
      ]
    },
    { path:'/entidade', name: 'Entidade', redirect: '/entidade/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Entidades', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Entidades'} 
          ]},
          props: {source:"entidade/lista"}},
        { path: 'novo', name: 'Novo Entidade', component: loadC('seguranca/MctEntidadeCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Entidades', to: '/entidade' }, 
            { text: 'Nova Entidade' }
          ]},      
          props: {source:"entidade/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Entidade', component: loadC('seguranca/MctEntidadeCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Entidades', to: '/entidade' }, 
            { text: 'Editar Entidade' }
          ]},
          props: {source:"entidade/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Entidade', component: loadC('seguranca/MctEntidadeCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Entidades', to: '/entidade' }, 
            { text: 'Deletar Entidade' }
          ]},      
          props: {source:"entidade/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Entidade', component: loadC('seguranca/MctEntidadeCad'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Entidades', to: '/entidade' }, 
            { text: 'Visualizar Entidade' }
          ]},
          props: {source:"entidade/editar",modo:'visualizar'} },
      ]
    },
    { path:'/unimedida', name: 'Unidade de Medida', redirect: '/unimedida/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Unidade de Medidas', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Unidade de Medidas'} 
          ]},
          props: {source:"unimedida/lista"}},
        { path: 'novo', name: 'Novo Unidade de Medida', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Unidade de Medidas', to: '/unimedida' }, 
            { text: 'Nova Unidade de Medida' }
          ]},      
          props: {source:"unimedida/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Unidade de Medida', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Unidade de Medidas', to: '/unimedida' }, 
            { text: 'Editar Unidade de Medida' }
          ]},
          props: {source:"unimedida/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Unidade de Medida', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Unidade de Medidas', to: '/unimedida' }, 
            { text: 'Deletar Unidade de Medida' }
          ]},      
          props: {source:"unimedida/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Unidade de Medida', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Unidade de Medidas', to: '/unimedida' }, 
            { text: 'Visualizar Unidade de Medida' }
          ]},
          props: {source:"unimedida/editar",modo:'visualizar'} },
      ]
    },
    { path:'/banco', name: 'Banco', redirect: '/banco/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Bancos', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bancos'} 
          ]},
          props: {source:"banco/lista"}},
        { path: 'novo', name: 'Novo Banco', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bancos', to: '/banco' }, 
            { text: 'Novo Banco' }
          ]},      
          props: {source:"banco/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Banco', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bancos', to: '/banco' }, 
            { text: 'Editar Banco' }
          ]},
          props: {source:"banco/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Banco', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bancos', to: '/banco' }, 
            { text: 'Deletar Banco' }
          ]},      
          props: {source:"banco/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Banco', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bancos', to: '/banco' }, 
            { text: 'Visualizar Banco' }
          ]},
          props: {source:"banco/editar",modo:'visualizar'} },
      ]
    },
    { path:'/agenciabancaria', name: 'Agência Bancária', redirect: '/agenciabancaria/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Agência Bancárias', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Agência Bancárias'} 
          ]},
          props: {source:"agenciabancaria/lista"}},
        { path: 'novo', name: 'Novo Agência Bancária', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Agência Bancárias', to: '/agenciabancaria' }, 
            { text: 'Nova Agência Bancária' }
          ]},      
          props: {source:"agenciabancaria/novo",modo:'novo'} },
        { path: ':age_codigo/:bnc_codigo/editar', name: 'Editar Agência Bancária', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Agência Bancárias', to: '/agenciabancaria' }, 
            { text: 'Editar Agência Bancária' }
          ]},
          props: {source:"agenciabancaria/editar",modo:'editar'} },
        { path: ':age_codigo/:bnc_codigo/deletar', name: 'Deletar Agência Bancária', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Agência Bancárias', to: '/agenciabancaria' }, 
            { text: 'Deletar Agência Bancária' }
          ]},      
          props: {source:"agenciabancaria/deletar",modo:'deletar'} },
        { path: ':age_codigo/:bnc_codigo/visualizar', name: 'Visualizar Agência Bancária', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Agência Bancárias', to: '/agenciabancaria' }, 
            { text: 'Visualizar Agência Bancária' }
          ]},
          props: {source:"agenciabancaria/editar",modo:'visualizar'} },
      ]
    },
    { path:'/profissao', name: 'Profissão', redirect: '/profissao/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Profissões', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Profissões'} 
          ]},
          props: {source:"profissao/lista"}},
        { path: 'novo', name: 'Novo Profissão', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Profissões', to: '/profissao' }, 
            { text: 'Nova Profissão' }
          ]},      
          props: {source:"profissao/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Profissão', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Profissões', to: '/profissao' }, 
            { text: 'Editar Profissão' }
          ]},
          props: {source:"profissao/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Profissão', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Profissões', to: '/profissao' }, 
            { text: 'Deletar Profissão' }
          ]},      
          props: {source:"profissao/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Profissão', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Profissões', to: '/profissao' }, 
            { text: 'Visualizar Profissão' }
          ]},
          props: {source:"profissao/editar",modo:'visualizar'} },
      ]
    },
    { path:'/grauinstrucao', name: 'Grau de Instrução', redirect: '/grauinstrucao/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Grau de Instrução', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Instrução'} 
          ]},
          props: {source:"grauinstrucao/lista"}},
        { path: 'novo', name: 'Novo Grau de Instrução', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Instrução', to: '/grauinstrucao' }, 
            { text: 'Novo Grau de Instrução' }
          ]},      
          props: {source:"grauinstrucao/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Grau de Instrução', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Instrução', to: '/grauinstrucao' }, 
            { text: 'Editar Grau de Instrução' }
          ]},
          props: {source:"grauinstrucao/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Grau de Instrução', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Instrução', to: '/grauinstrucao' }, 
            { text: 'Deletar Grau de Instrução' }
          ]},      
          props: {source:"grauinstrucao/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Grau de Instrução', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Instrução', to: '/grauinstrucao' }, 
            { text: 'Visualizar Grau de Instrução' }
          ]},
          props: {source:"grauinstrucao/editar",modo:'visualizar'} },
      ]
    },
    { path:'/grauparentesco', name: 'Grau de Parentesco', redirect: '/grauparentesco/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Grau de Parentesco', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Parentesco'} 
          ]},
          props: {source:"grauparentesco/lista"}},
        { path: 'novo', name: 'Novo Grau de Parentesco', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Parentesco', to: '/grauparentesco' }, 
            { text: 'Novo Grau de Parentesco' }
          ]},      
          props: {source:"grauparentesco/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Grau de Parentesco', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Parentesco', to: '/grauparentesco' }, 
            { text: 'Editar Grau de Parentesco' }
          ]},
          props: {source:"grauparentesco/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Grau de Parentesco', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Parentesco', to: '/grauparentesco' }, 
            { text: 'Deletar Grau de Parentesco' }
          ]},      
          props: {source:"grauparentesco/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Grau de Parentesco', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Grau de Parentesco', to: '/grauparentesco' }, 
            { text: 'Visualizar Grau de Parentesco' }
          ]},
          props: {source:"grauparentesco/editar",modo:'visualizar'} },
      ]
    },
    { path:'/escmidias', name: 'Mídia', redirect: '/escmidias/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Mídias', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Mídias'} 
          ]},
          props: {source:"escmidias/lista"}},
        { path: 'novo', name: 'Novo Mídia', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Mídias', to: '/escmidias' }, 
            { text: 'Nova Mídia' }
          ]},      
          props: {source:"escmidias/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Mídia', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Mídias', to: '/escmidias' }, 
            { text: 'Editar Mídia' }
          ]},
          props: {source:"escmidias/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Mídia', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Mídias', to: '/escmidias' }, 
            { text: 'Deletar Mídia' }
          ]},      
          props: {source:"escmidias/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Mídia', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Mídias', to: '/escmidias' }, 
            { text: 'Visualizar Mídia' }
          ]},
          props: {source:"escmidias/editar",modo:'visualizar'} },
      ]
    },

];