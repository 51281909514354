<template>
    <!-- /Sidebar Left -->
<div id="app-sidebar" class="sidebar" :class="{'sidebar-notactive' : !sidebarActive,'sidebar-active' : sidebarActive, 'sidebar-reduced' : sidebarReduce && sidebarActive}">
    <div id="app-sidebar-modulo">
        <a href="#" class="center" :style="`background-color: ${infoModulo.mod_cor}!important;`" :alt="infoModulo.mod_desclonga" :title="infoModulo.mod_desclonga">
        <div>
            <img src="@/assets/modulos/SIGES.png" :alt="infoModulo.mod_desclonga">
        </div>
        </a>
    </div>
            
    <ul class="nav-links">
      <li v-if="this.Exercicio > 0">
          <div id="app-sidebar-exercicio">
              <a href="#" @click="getIframe($event,'/exercicios')">
                  {{ this.Exercicio }}
              </a>
          </div>
      </li>
      <li>
          <div id="app-sidebar-datahora">
              <span id="app-sidebar-data">00/00/0000</span>
              <span id="app-sidebar-hora">00:00:00</span>
          </div>
      </li>

      <template v-for="(pai,idx) in infoMenuSideBar">
        <li :key="idx" v-if="pai.title === false && (!pai.children || pai.children.length === 0)" :class="pai.title === true ? 'nav-title' : ''">
          <router-link :to="pai.url">
            <i class='fa fa-lg' :class="pai.icon ? pai.icon : 'fa-folder-open-o'"></i>
            <span class="link_name">{{pai.name}}</span>
          </router-link>
          <ul class="sub-menu blank">
            <li>
              <router-link :to="pai.url" class="link_name">{{pai.name}}</router-link>
            </li>
          </ul>
        </li>

        <li :key="idx" v-else-if="pai.title === true" class="nav-title">
          {{pai.name}}
        </li>

        <li :id="'li'+idx" :key="idx" v-else>
          <div class="iocn-link">
            <a href="#">
             <i class='fa fa-lg' :class="pai.icon ? pai.icon : 'fa-folder-open-o'"></i>
              <span class="link_name">{{pai.name}}</span>
            </a>
            <i class='fa fa-angle-down arrow' v-if="pai.children" @click="onToggleShow('li'+idx)"></i>
          </div>
          <ul class="sub-menu" v-if="pai.children">
            <li>
              <router-link :to="pai.url" class="link_name">
                  <i class='fa fa-lg' :class="pai.icon ? pai.icon : 'fa-folder-open-o'"></i>
                  <span >{{pai.name}}</span>
              </router-link>
            </li>
            <template v-for="(filho,idxf) in pai.children">
              <li :key="idxf">
                <router-link :to="filho.url">
                  <i class="fa fa-lg mr-2" :class="filho.icon ? filho.icon : 'fa-folder-open-o'"></i>
                  <span >{{filho.name}}</span>
                </router-link>
              </li>

            </template>
          </ul>
        </li>
      </template>
      <li>
        <div class="user-details">
          <div class="user-content">
              <img v-if="infoLogado.usu_foto_url !== ''" :src="infoLogado.usu_foto_url" alt="Avatar">
              <img v-else src="@/assets/images/sem_foto_branco.png" alt="Avatar">
          </div>
          <div>
              <div class="user_name">{{infoLogado.usu_nome}}</div>
              <div class="user_group">{{infoLogado.usu_funcao === 1 ? 'ADMINISTRADOR' : infoLogado.usu_funcao === 2 ? 'GERENTE' : infoLogado.usu_funcao === 3 ? 'FINANCEIRO' : infoLogado.usu_funcao === 4 ? 'FUNCIONÁRIO' : ''}}</div>
              <div class="user_btns d-flex flex-row m-0 p-0">
                <i class="fa fa-id-card-o" title="Dados do Usuário" @click="$router.push('/dadosusuario')"></i>
                <i class="fa fa-unlock" @click="$router.push('/alterarsenha')" title="Alterar Senha"></i>
                <i class="fa fa-cogs" @click="$router.push('/configuracoes')" title="Configurações"></i>
              </div>
          </div>
          <i class='fa fa-sign-out' @click="onLogout()" title="Sair da Aplicação"></i>
        </div>
    </li>
    </ul>
  <button class="button-reduce text-right" @click="$emit('update:sidebarReduce', !sidebarReduce)"><i class="fa fa-chevron-left fa-lg"></i></button>     
  </div>   
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'LayoutDefaultSideBar',
    props: ['sidebarActive','sidebarReduce'],
    computed: {
        ...mapState('Login',['infoLogado','infoModulo','infoSubModulo','infoMenuSideBar','Entidade','Exercicio','Competencia'])
    },
    mounted () {
        window.setInterval(() => {
            let momentoAtual = new Date(); 
            let hora = momentoAtual.getHours();
            let minuto = momentoAtual.getMinutes(); 
            let segundo = momentoAtual.getSeconds(); 

            let dia = momentoAtual.getDate();
            let mes = momentoAtual.getMonth()+1; 
            let ano = momentoAtual.getFullYear(); 

            let str_segundo = new String (segundo); 
            if (str_segundo.length == 1) 
                segundo = "0" + segundo; 

            let str_minuto = new String (minuto);
            if (str_minuto.length == 1) 
                minuto = "0" + minuto; 

            let str_hora = new String (hora); 
            if (str_hora.length == 1) 
                hora = "0" + hora; 

            let str_dia = new String (dia); 
            if (str_dia.length == 1) 
                dia = "0" + dia; 

            let str_mes = new String (mes); 
            if (str_mes.length == 1) 
                mes = "0" + mes; 

            let dataImprimivel = dia + "/" + mes + "/" + ano;
            let horaImprimivel = hora + ":" + minuto + ":" + segundo;

            $( "#app-sidebar-data" ).text(dataImprimivel) ;

            $( "#app-sidebar-hora" ).text(horaImprimivel) ;             
        },1000);
    },
    updated () {
      /*let arrow = document.querySelectorAll(".arrow");
        for (var i = 0; i < arrow.length; i++) {
            arrow[i].addEventListener("click", (e)=>{
                let arrowParent = e.target.parentElement.parentElement;//selecting main parent of arrow
                arrowParent.classList.toggle("showMenu");
                console.log(arrowParent);
            });
        }*/      
    },
    methods: {
      getIframe: function (ev, pagina) {
        ev.preventDefault()
        if (pagina.menu_pagina != ''){
            this.$router.push({path: pagina,  props: pagina})  
        }
      },      
      onToggleShow(e){
        let arrow = document.getElementById(e);
        arrow.classList.toggle("showMenu");
      },
      onLogout () {
        this.$swal({
          title: 'Deseja realmente sair da aplicação?',
          text: "Sair da aplicação!",
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
          })
          .then((result) => {
            if (result.value) {
              this.$store.dispatch('Login/USU_LOGOUT');
            }
          })
      }
    }   
}
</script>

<style lang="scss">
  @import '@/assets/scss/sidebar';
</style>
