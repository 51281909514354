<template>
  <div id="app" class="d-flex flex-column">

    <nav id="app-navbar" class="navbar navbar-expand-lg d-flex flex-row justify-content-between">
      <img class="app-logo" src="@/assets/images/logo_siges.svg" alt="Logo">
      <div class="app-logo-text" :class="!sidebarReduce && sidebarActive ? '' : 'sidebar-reduced'"><span>SIGES</span></div>      
      <a class="navbar-brand" href="#" @click="sidebarActive = !sidebarActive"><i class="fa fa-bars"></i></a>
      <div class="d-flex flex-row justify-content-start w-100">
        <mct-menu @setPatch="setPatch"></mct-menu>      
      </div>      
      <a class="navbar-brand" href="#" @click="asideActive = !asideActive"><i class="fa fa-bars"></i></a>      
    </nav>

    <div id="app-wrapper" class="wrapper">
      <side-bar :sidebarActive.sync='sidebarActive' :sidebarReduce.sync='sidebarReduce'/>

      <div id="app-content" class="content" :class="{'sidebar-notactive' : !sidebarActive,'sidebar-active' : sidebarActive,'aside-active' : asideActive, 'sidebar-reduced' : sidebarReduce && sidebarActive}">        
        <router-view></router-view>
      </div>

      <a-side :asideActive="asideActive"/>
    </div>

    <div id="app-footer" class="footer" :class="{'sidebar-notactive' : !sidebarActive,'sidebar-active' : sidebarActive,'aside-active' : asideActive, 'sidebar-reduced' : sidebarReduce && sidebarActive}">
      <div>
        <a href="https://www.mercatosa.com.br">Mercato</a>
        <span class="ml-1">&copy; {{moment().year()}}</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Desenvolvido por</span>
        <a href="https://www.mercatosa.com.br">Mercato SA</a>
      </div>      
    </div>
    <!--<  name="mct-portal" class="mct-portal">
            
    </portal-target>-->
    <modal/>
  </div>    
</template>

<script>
import { mapState } from 'vuex'

import MctTopo from '@/components/comum/MctTopo.vue'
import MctMenu from '@/components/comum/MctMenu.vue'

import ASide from './LayoutDefaultASide.vue';
import SideBar from './LayoutDefaultSideBar.vue';

import { Modal } from '@/components/modal'

export default {
  name: 'LayoutDefault',
  data () {
    return {
      sidebarActive: true,
      asideActive: false,
      sidebarReduce: true,
    }
  },
  components: {
    MctTopo,
    MctMenu,
    ASide,
    SideBar,
    Modal
  },
  methods: {
      setPatch (pagina) {
        if (this.infoLogado.usu_codigo){        
          if (pagina.menu_pagina != ''){
            this.$router.push({path: pagina.menu_pagina,  props: pagina})  
          }
        }else{
            this.$router.push({path: '/logar'})  
        }
      },
      setSide (pagina) {
        if (this.infoLogado.usu_codigo){        
          if (pagina != ''){
            this.$router.push({path: pagina})  
          }
        }else{
            this.$router.push({path: '/logar'})  
        }
      },
  },  
  mounted () {
    if (!this.infoLogado.usu_codigo){    
      this.showSideBar = false    
      this.$router.push({path: '/logar'})  
    } else{
      this.showSideBar = true    
    }
  },
  updated () {
    if (!this.infoLogado.usu_codigo){        
      this.showSideBar = false
      this.$router.push({path: '/logar'})  
    } else{
      this.showSideBar = true
    }    
  },
  computed: {
    ...mapState('Login',['infoLogado','infoModulo','infoSubModulo','Entidade','Exercicio','Competencia']),
  },
  sockets: {
    mctalerta: function (data) {
      this.$store.dispatch('App/APP_ATU_ALERTAS',data);
      this.$awn.info(`Notificação Recebida: ${data.ale_descricao}.`)
    },
  },  
}
</script>