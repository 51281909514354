import Vue from 'vue'
import App from './App'
import router from './router'

import BootstrapVue from 'bootstrap-vue'
import Loader from './plugins/loader'
import store from './store'
import VueTheMask from 'vue-the-mask'
import moment from 'moment-timezone'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

import MoedaFilter from './filters/filterMoeda'
import MoedaDifNullFilter from './filters/filterMoedaDifNull'
import DataFilter from './filters/filterData'
import DataHoraFilter from './filters/filterDataHora'
import HoraFilter from './filters/filterHora'
import HorMinFilter from './filters/filterHorMin'
import HorMinStrFilter from './filters/filterHorMinStr'
import ViaCep from 'vue-viacep'
import html2canvas from 'html2canvas'
import NProgress from 'nprogress'
import PortalVue from 'portal-vue'
import VueCurrencyInput from 'vue-currency-input'

const pluginOptions = {
  /* see config reference */
  globalOptions: { locale: 'pt-BR', currency: 'BRL', precision: 2 , distractionFree: {hideCurrencySymbol: true} }
}
Vue.use(VueCurrencyInput, pluginOptions)


let PluginNProgress = {
  install(Vue, options) {
    window.NProgress = NProgress
  }
}
Vue.use(PluginNProgress)

let MyPlugin = {
  install(Vue, options) {
    window.html2canvas = html2canvas
  }
}
Vue.use(MyPlugin)

import 'nprogress/nprogress.css'
import 'bootstrap';


Vue.use(ViaCep);

Vue.use(BootstrapVue)
Vue.use(PortalVue)

//Vue.use(VueRouterMultiView)
Vue.use(Loader, store)
Vue.prototype.moment = moment
Vue.use(VueTheMask)

const optionsSW2 = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
  //confirmButtonColor: '#3085d6',
  //cancelButtonColor: '#d33',
  cancelButtonText: 'Não',
  confirmButtonText: 'Sim'

};
Vue.use(VueSweetalert2,optionsSW2);

import vSelect from 'vue-select';

Vue.component('v-select', vSelect);
Vue.filter('moeda', MoedaFilter)
Vue.filter('moedadifnull', MoedaDifNullFilter)
Vue.filter('data', DataFilter)
Vue.filter('datahora', DataHoraFilter)
Vue.filter('hora', HoraFilter)
Vue.filter('horamin', HorMinFilter)
Vue.filter('horaminstr', HorMinStrFilter)

import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
 
const socket = io(process.env.VUE_APP_BASE_SOCKET);
 
Vue.use(VueSocketIOExt, socket);

import VueAWN from "vue-awesome-notifications"
Vue.use(VueAWN,{
  labels: {
    tip: 'Dica',
    info: 'Informação',
    success: 'Sucesso',
    warning: 'Atenção',
    alert: 'Erro',
    async: 'Carregando',
    confirm: 'Confirmação requerida',
    confirmOk: 'OK',
    confirmCancel: 'Cancela”   ',
  }})
require("vue-awesome-notifications/dist/styles/style.css")

window.$ = window.jQuery = require('jquery');

import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
Vue.use(VueFormWizard)

import print from 'print-js'

let MyPluginPrint = {
  install(Vue, options) {
    //window.print = print
  }
}
Vue.use(MyPluginPrint)

import UUID from 'vue-uuid';
 
Vue.use(UUID);

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})