import Vue from 'vue'
import Vuex from 'vuex'


import Configs from './configs'
import App from './app'
import Login from './login'
import Portal from './portal'
import Padrao from './padrao'
//import Popup from './popup'
//import Loader from '../plugins/loader'
//import Pais from './pais'

const modules = {
    App,
    Login,
    Portal,
    Padrao,
    Configs
}

Vue.use(Vuex)

export default new Vuex.Store({
    modules
})