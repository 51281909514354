function load(component) {
  return () => import(`@/components/comum/${component}`)
}

  export default [
      
    { path:'/nacionalidade', name: 'Nacionalidade', redirect: '/nacionalidade/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path: 'lista', name: 'Listagem de Nacionalidade', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Nacionalidade' } 
          ]},
          props: {source:"nacionalidade/lista"} },
        { path: 'novo', name: 'Nova Nacionalidade', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Nacionalidade', to: '/nacionalidade' }, 
            { text: 'Nova Nacionalidade' }
          ]},
        props: {source:"nacionalidade/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Nacionalidade', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Nacionalidade', to: '/nacionalidade' }, 
            { text: 'Editar Nacionalidade' }
          ]},
          props: {source:"nacionalidade/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Nacionalidade', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Nacionalidade', to: '/nacionalidade' }, 
            { text: 'Deletar Nacionalidade' }
          ]},
          props: {source:"nacionalidade/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Nacionalidade', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Nacionalidade', to: '/nacionalidade' }, 
            { text: 'Visualizar Nacionalidade' }
          ]},
          props: {source:"nacionalidade/editar",modo:'visualizar'} },
      ]
    },

    { path:'/pais', name: 'Pais', redirect: '/pais/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Paises', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Paises'} 
          ]},
          props: {source:"pais/lista"}},
        { path: 'novo', name: 'Novo Pais', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Paises', to: '/pais' }, 
            { text: 'Novo Pais' }
          ]},      
          props: {source:"pais/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Pais', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Paises', to: '/pais' }, 
            { text: 'Editar Pais' }
          ]},
          props: {source:"pais/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Pais', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Paises', to: '/pais' }, 
            { text: 'Deletar Pais' }
          ]},      
          props: {source:"pais/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Pais', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Paises', to: '/pais' }, 
            { text: 'Visualizar Pais' }
          ]},
          props: {source:"pais/editar",modo:'visualizar'} },
      ]
    },

    { path:'/estado', name: 'Estado', redirect: '/estado/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Estados', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Estados'} 
          ]},
          props: {source:"estado/lista"}},
        { path: 'novo', name: 'Novo Estado', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Estados', to: '/estado' }, 
            { text: 'Novo Estado' }
          ]},      
          props: {source:"estado/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Estado', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Estados', to: '/estado' }, 
            { text: 'Editar Estado' }
          ]},
          props: {source:"estado/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Estado', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Estados', to: '/estado' }, 
            { text: 'Deletar Estado' }
          ]},      
          props: {source:"estado/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Estado', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Estados', to: '/estado' }, 
            { text: 'Visualizar Estado' }
          ]},
          props: {source:"estado/editar",modo:'visualizar'} },
      ]
    },
  
    { path:'/cidade', name: 'Cidade', redirect: '/cidade/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Cidades', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Cidades'} 
          ]},
          props: {source:"cidade/lista"}},
        { path: 'novo', name: 'Nova Cidade', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Cidades', to: '/cidade' }, 
            { text: 'Nova Cidade' }
          ]},      
          props: {source:"cidade/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Cidade', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Cidades', to: '/cidade' }, 
            { text: 'Editar Cidade' }
          ]},
          props: {source:"cidade/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Cidade', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Cidades', to: '/cidade' }, 
            { text: 'Deletar Cidade' }
          ]},      
          props: {source:"cidade/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Cidade', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Cidades', to: '/cidade' }, 
            { text: 'Visualizar Cidade' }
          ]},
          props: {source:"cidade/editar",modo:'visualizar'} },
      ]
    },
    
    { path:'/bairro', name: 'Bairro', redirect: '/bairro/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Bairros', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bairros'} 
          ]},
          props: {source:"bairro/lista"}},
        { path: 'novo', name: 'Novo Bairro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bairros', to: '/bairro' }, 
            { text: 'Novo Bairro' }
          ]},      
          props: {source:"bairro/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Bairro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bairros', to: '/bairro' }, 
            { text: 'Editar Bairro' }
          ]},
          props: {source:"bairro/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Bairro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bairros', to: '/bairro' }, 
            { text: 'Deletar Bairro' }
          ]},      
          props: {source:"bairro/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Bairro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Bairros', to: '/bairro' }, 
            { text: 'Visualizar Bairro' }
          ]},
          props: {source:"bairro/editar",modo:'visualizar'} },
      ]
    },
    
    { path:'/tplogradouro', name: 'Tipos de Logradouro', redirect: '/tplogradouro/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Tipos de Logradouros', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Tipos de Logradouros'} 
          ]},
          props: {source:"tplogradouro/lista"}},
        { path: 'novo', name: 'Novo Tipo de Logradouro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Tipos de Logradouros', to: '/tplogradouro' }, 
            { text: 'Novo Tipo de Logradouro' }
          ]},      
          props: {source:"tplogradouro/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Tipo de Logradouro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Tipos de Logradouros', to: '/tplogradouro' }, 
            { text: 'Editar Tipo de Logradouro' }
          ]},
          props: {source:"tplogradouro/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Tipo de Logradouro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Tipos de Logradouros', to: '/tplogradouro' }, 
            { text: 'Deletar Tipo de Logradouro' }
          ]},      
          props: {source:"tplogradouro/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Tipo de Logradouro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Tipos de Logradouros', to: '/tplogradouro' }, 
            { text: 'Visualizar Tipo de Logradouro' }
          ]},
          props: {source:"tplogradouro/editar",modo:'visualizar'} },
      ]
    },
    
    { path:'/logradouro', name: 'Logradouro', redirect: '/logradouro/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path:'lista', name: 'Listagem de Logradouros', component: load('MctDataViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Logradouros'} 
          ]},
          props: {source:"logradouro/lista"}},
        { path: 'novo', name: 'Novo Logradouro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Logradouros', to: '/logradouro' }, 
            { text: 'Novo Logradouro' }
          ]},      
          props: {source:"logradouro/novo",modo:'novo'} },
        { path: ':codigo/editar', name: 'Editar Logradouro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Logradouros', to: '/logradouro' }, 
            { text: 'Editar Logradouro' }
          ]},
          props: {source:"logradouro/editar",modo:'editar'} },
        { path: ':codigo/deletar', name: 'Deletar Logradouro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Logradouros', to: '/logradouro' }, 
            { text: 'Deletar Logradouro' }
          ]},      
          props: {source:"logradouro/deletar",modo:'deletar'} },
        { path: ':codigo/visualizar', name: 'Visualizar Logradouro', component: load('MctFormViewer'), 
          meta: { breadcrumb: [
            { text: 'Listagem de Logradouros', to: '/logradouro' }, 
            { text: 'Visualizar Logradouro' }
          ]},
          props: {source:"logradouro/editar",modo:'visualizar'} },
      ]
    },
    
];