<template>
<header class="app-header navbar">
    <button class="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" @click="mobileSidebarToggle" >
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" to="#"></a>
    <button class="navbar-toggler sidebar-toggler d-md-down-none" type="button" data-toggle="sidebar-lg-show" @click="sidebarToggle" v-if="infoLogado.usu_codigo && infoModulo.mod_codigo">
      <span class="navbar-toggler-icon"></span>
    </button>
    <slot></slot>    
    <ul class="nav navbar-nav ml-auto">    
    </ul>
    
    <button class="navbar-toggler aside-menu-toggler d-md-down-none" type="button" @click="asideToggle" v-if="infoLogado.usu_codigo && infoModulo.mod_codigo">
      <span class="navbar-toggler-icon"></span>
    </button>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MctTopo',
  data () {
    return {
    }
  },
  methods: {
      sidebarToggle (e) {
          e.preventDefault()
          document.body.classList.toggle('sidebar-hidden')
      },
      sidebarMinimize (e) {
          e.preventDefault()
          document.body.classList.toggle('sidebar-minimized')
      },
      mobileSidebarToggle (e) {
          e.preventDefault()
          document.body.classList.toggle('sidebar-mobile-show')
      },
      asideToggle (e) {
          e.preventDefault()
          document.body.classList.toggle('aside-menu-hidden')
      }
  },
  computed: {
    ...mapState('Login',['infoLogado', 'infoModulo']),
  },     
}

</script>

