import Vue from "vue";
import VueRouter from "vue-router";
import routes from './routes'
import store from '../store';
import swal from 'sweetalert2';
import {verificaPermissao} from '@/components/funcoes/Funcoes.js'
  
Vue.use(VueRouter);


const router = new VueRouter({
    //mode: 'history',
    mode: 'abstract',
    /*mode: 'hash',*/
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),    
    routes   
});

router.beforeEach((to, from, next) => {

    
    const token = to.path.substr(0,7) === '/portal' ? localStorage.getItem('mct_portal_token') : localStorage.getItem('mct_user_token');
    
    if (!token && to.path !== '/portal/logar' && to.path.substr(0,7) === '/portal') {   
      //console.log('entrei'); 
      next('/portal/logar')
      return;
    } else
    if (!token && to.path !== '/logar' && to.path.substr(0,7) !== '/portal') {    
      next('/logar')
      return;
    }  

    if (verificaPermissao(to.path))
      next();
});


router.beforeResolve((to, from, next) => {
  // Se este não for um carregamento inicial da página
  if (to.name) {
    // Iniciar a barra de progresso da rota
    //NProgress.start()
  }
  next()
});

router.afterEach((to, from) => {
  // Complete a animação da barra de progresso da rota
  //NProgress.done()
});

export default router;