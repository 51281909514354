import * as MutationTypes from './MutationTypes';
import axios from "@/http";

const state = {    
    padraoData: [],
};

const mutations = {       
    [MutationTypes.PADRAO_FILTRO_CAMPO](state, response) {
        state.padraoData = response
    },
       
};

const actions = {
    [MutationTypes.PADRAO_FILTRO_CAMPO]({commit},data) {
        axios.get(data[1],{params:  {
            column: data[0].nome,
            direction: 'asc',
            page: 1,
            per_page: 15,
            search_column: data[0].nome,
            search_operator: 'like',
            search_input: data[2]
        },
        paramsSerializer: function (params) {
          return jQuery.param(params)
        }
        })
        .then(response => {
            commit(MutationTypes.PADRAO_FILTRO_CAMPO, [response.data.data, data[0].nome, data[2]]);
        })
        .catch(response => {
          console.log(response.message)
        })
        
    }

};

const getters = {
    padraoData: state => state.padraoData,
};


export default{
    namespaced: true,
    state, 
    actions, 
    mutations, 
    getters
}